export const revenueTracking = () => {
	const url = new URL(window.location.href);
	const searchParams = url.searchParams;
	let foundUtm = false;
	for (const key of searchParams.keys()) {
		if (key.includes('utm_')) {
			foundUtm = true;
		} else {
			searchParams.delete(key);
		}
	}
	if (foundUtm) {
		sessionStorage.setItem('campaign', searchParams.toString());
	}
};
