<script setup lang="ts">
import { Button } from '@laam/ui/base';
import CategoryCard from '~/components/homepage/CategoryCard.vue';

interface CategoriesListProps {
	showTitle: boolean;
	data: any;
	l1Title: string;
	variant: 'category' | 'home';
	viewAllHandle: string;
	index: number;
}

const props = defineProps<CategoriesListProps>();

const filteredData = computed(() => {
	return props.data.filter((item: { children: object }) => {
		if (!item.children) {
			return item;
		} else if (props.l1Title === 'Kids') {
			return item;
		} else {
			return null;
		}
	});
});

const setSelectedTab = inject('setSelectedTab') as (tab: string) => void;
const handleCategoryClick = (category: string) => {
	setSelectedTab(category);
};
</script>

<template>
	<div class="categories-list gap-3xl flex flex-col">
		<div v-if="props.showTitle" class="gap-xs flex flex-col">
			<span class="text-lg font-semibold text-gray-800">Shop by Category</span>

			<span class="text-xs text-gray-600">Inspired by your interests</span>
		</div>

		<div
			v-if="variant === 'category'"
			class="gap-md lg:gap-3xl grid grid-cols-3"
		>
			<NuxtLink
				:to="viewAllHandle"
				class="rounded-xs aspect-square relative flex flex-col items-center justify-center uppercase bg-gray-100"
				@click="handleCategoryClick('home')"
			>
				<span class="text-xs font-bold text-gray-300">shop all</span>
				<span class="text-xs font-bold text-gray-800">shop all</span>
				<span class="text-xs font-bold text-gray-300">shop all</span>
			</NuxtLink>
			<div
				v-for="(category, horizontalIndex) in filteredData"
				:key="horizontalIndex"
			>
				<CategoryCard
					:redirect="`/nodes/${l1Title.toLowerCase()}-${category.title.toLowerCase().split(' ').join('-')}-${category.id}`"
					:data="category"
					:horizontal-index="horizontalIndex"
					:vertical-index="index"
					:parent-title="props.showTitle ? 'Shop by Category' : ''"
					:data-source="{
						dataSource: 'valhalla',
						version: 'v0',
						clickedFrom: 'category-listing',
					}"
					data-testid="category-card"
					@click="handleCategoryClick('home')"
				/>
			</div>
		</div>

		<Button
			v-if="variant === 'home'"
			variant="secondary"
			size="md"
			class="!py-md"
		>
			View More
		</Button>
	</div>
</template>
