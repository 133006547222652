<script setup lang="ts">
import type { ShippingConfig } from '~/types/statsig';

const CurrencyStore = useCurrency();
const { currencySymbol } = storeToRefs(CurrencyStore);
const { getFormattedPrice } = CurrencyStore;
const shippingConfig = inject('shippingConfig') as Ref<ShippingConfig>;

const text = computed(() => {
	if (shippingConfig.value) {
		return shippingConfig.value?.text;
	}
	return null;
});

const cutoff = computed(() => {
	if (shippingConfig.value && shippingConfig.value.freeShipping)
		return shippingConfig.value.freeShippingCutoff;
	else return false;
});

const countryName = computed(() => {
	if (shippingConfig.value) {
		return shippingConfig.value?.country;
	}
	return null;
});
</script>
<template>
	<PhTruck class="size-3xl" />
	<!-- free shipping -->
	<div v-if="cutoff" class="gap-xs flex items-center font-semibold">
		<span> {{ text }}</span>
		<span v-if="cutoff" class="flex">
			{{ getFormattedPrice(cutoff) }}
		</span>
		<span v-if="countryName"> to {{ countryName }} </span>
	</div>

	<!-- discounted shipping -->
	<div v-else class="gap-xs flex items-center font-semibold">
		<span> {{ text }} </span>
		<span v-if="cutoff" class="flex">
			above {{ `${currencySymbol} ${getFormattedPrice(cutoff)}` }}
		</span>
		<span v-if="countryName"> to {{ countryName }} </span>
	</div>
</template>
