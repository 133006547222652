<script setup lang="ts">
import { Button } from '@laam/ui/base';
import { useRoute } from 'vue-router';

const route = useRoute();

const activeHomepage = computed(() => {
	if (route.path === '/men') {
		return 'men';
	} else if (route.path === '/kids') {
		return 'kids';
	} else if (route.path === '/women') {
		return 'women';
	} else {
		return 'all';
	}
});
const isVisible = computed(() => route.name === 'identifier');
const showNov11Sale = inject('showNov11Sale') as boolean;
</script>
<template>
	<div
		v-if="isVisible"
		class="homepage--switch gap-md lg:hidden border-bottom flex items-center bg-white border-gray-200"
	>
		<NuxtLink
			to="/"
			class="relative flex-1"
			:class="{
				'border-b-2 border-gray-800 ': activeHomepage === 'all',
				'!border-error-900 ': showNov11Sale,
			}"
		>
			<Button
				size="md"
				variant="tertiary"
				class="text-md w-full font-semibold text-gray-500"
			>
				<p
					v-if="activeHomepage === 'all'"
					class="text-gray-800"
					:class="{
						'!text-error-900': showNov11Sale,
					}"
				>
					All
				</p>

				<p v-else>All</p>
			</Button>
		</NuxtLink>
		<NuxtLink
			to="/women"
			class="relative flex-1"
			:class="{
				'border-b-2 border-gray-800': activeHomepage === 'women',
			}"
		>
			<Button
				size="md"
				variant="tertiary"
				class="text-md w-full font-semibold text-gray-500"
			>
				<p v-if="activeHomepage === 'women'" class="text-gray-800">Women</p>

				<p v-else>Women</p>
			</Button>
		</NuxtLink>
		<NuxtLink
			to="/men"
			class="flex-1"
			:class="{
				'border-b-2 border-gray-800': activeHomepage === 'men',
			}"
		>
			<Button
				size="md"
				variant="tertiary"
				class="text-md w-full font-semibold text-gray-500"
			>
				<p v-if="activeHomepage === 'men'" class="text-gray-800">Men</p>

				<p v-else>Men</p>
			</Button>
		</NuxtLink>
		<NuxtLink
			to="/kids"
			class="flex-1"
			:class="{
				'border-b-2 border-gray-800': activeHomepage === 'kids',
			}"
		>
			<Button
				size="md"
				variant="tertiary"
				class="text-md w-full font-semibold text-gray-500"
			>
				<p v-if="activeHomepage === 'kids'" class="text-gray-800">Kids</p>

				<p v-else>Kids</p>
			</Button>
		</NuxtLink>
	</div>
</template>
