<script setup lang="ts">
import { Button, Dialog, DialogContent, DialogFooter } from '@laam/ui/base';

const isOpen = ref(true);

const isIOS = ref<boolean>(false);
const isAndroid = ref<boolean>(false);

const computeAppBanner = () => {
	const userAgent = navigator.userAgent;
	if (userAgent) {
		const iOS = /iPhone|iPad|iPod/i.test(userAgent);
		const android = /Android/i.test(userAgent);

		isIOS.value = iOS;
		isAndroid.value = android;

		return;
	}

	isIOS.value = false;
	isAndroid.value = false;
};

const handleButtonClick = () => {
	if (isIOS.value) {
		navigateTo(
			'https://apps.apple.com/tr/app/laam-app/id6476638006?utm_campaign=appinstall',
			{ external: true },
		);
		isOpen.value = false;
	} else if (isAndroid.value) {
		navigateTo(
			'https://play.google.com/store/apps/details?id=com.laam.laam_app&referrer=utm_source%3Ddirect%26utm_medium%3Dbanner%26utm_campaign%3Dappinstall',
			{ external: true },
		);
		isOpen.value = false;
	}
};

onMounted(() => {
	computeAppBanner();
});
</script>

<template>
	<Dialog :open="isOpen" @update:open="isOpen = !isOpen">
		<DialogContent>
			<div class="flex flex-col gap-md items-center">
				<NuxtImg :src="'./stars.png'" alt="Confetti" class="w-fit" />
				<h1 class="text-lg font-semibold">Welcome to LAAM Campus Program!</h1>
				<p class="text-sm max-w-[480px] text-center">
					We're thrilled to have you join us. We hope you enjoy your shopping
					experience at LAAM.
				</p>
			</div>
			<DialogFooter class="!justify-center !flex-col items-center">
				<a href="/">
					<Button size="xs"> Get Started! </Button>
				</a>
				<div v-if="isIOS || isAndroid" class="flex gap-md items-center">
					<Button
						size="xs"
						variant="tertiary"
						class="underline text-blue-600 !p-0"
						@click="handleButtonClick"
					>
						Click here
					</Button>
					{{ ' to download the app' }}
				</div>
			</DialogFooter>
		</DialogContent>
	</Dialog>
</template>
