<script setup lang="ts">
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@laam/ui/base';

import {
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
	DialogTrigger,
	DialogClose,
} from 'radix-vue';
import CategoriesList from '~/components/navigation/mobile/CategoriesList.vue';
import { useFetchCategories } from '~/data/entities';

const { data: categories } = useFetchCategories();

const routeToFeed = (l1NodeTitle: string) => {
	return `/${l1NodeTitle.toLowerCase()}`;
};

const activeL1Node = ref('');
</script>

<template>
	<Accordion
		v-for="(l1Node, index) in categories"
		:key="index"
		v-model:model-value="activeL1Node"
		class="w-full"
		type="single"
	>
		<AccordionItem
			:key="l1Node.title"
			:value="l1Node.title"
			class="border-none"
		>
			<AccordionTrigger
				:value="l1Node.title"
				class="py-xl px-xl text-md font-medium text-gray-800 capitalize !border-b-0 hover:bg-gray-50"
				data-testid="menu-l1-trigger"
			>
				<NuxtLink :href="routeToFeed(l1Node.title)">
					{{ l1Node.title }}
				</NuxtLink>
			</AccordionTrigger>

			<AccordionContent>
				<div
					v-for="l2Node in l1Node.children"
					:key="l1Node.title + l2Node.title"
					:value="l1Node.title + l2Node.title"
				>
					<DialogRoot>
						<DialogTrigger
							v-if="l2Node.title !== 'Lingerie and Sleepwear'"
							class="py-md justify-start text-gray-700 text-left w-full font-sm font-medium pl-8xl pr-xl data-[state=open]:font-bold data-[state=open]:bg-white data-[active]:bg-white hover:bg-gray-50"
							data-testid="menu-l2-trigger"
						>
							{{ l2Node.title }}
						</DialogTrigger>

						<DialogPortal>
							<DialogOverlay
								class="bg-gray-800/60 data-[state=open]:animate-overlay-show fixed top-[73px] right-0 left-[250px] bottom-0 z-30"
							/>

							<DialogContent
								disable-teleport
								:show-close="false"
								class="data-[state=open]:animate-content-show fixed max-h-[100vh] overflow-auto bg-white focus:outline-none h-full left-[250px] top-[73px] z-50 w-full max-w-lg -translate-x-0 -translate-y-0 border-r !rounded-none p-7xl duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out !border-none data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 pb-[100px]"
							>
								<div
									v-for="(l3Node, l3Index) in l2Node.children"
									:key="l2Node.title + l3Node.title"
								>
									<Accordion
										v-if="l2Node.hasOwnProperty('children')"
										:key="l1Node.title + l2Node.title"
										class="w-full"
										:ref_key="l1Node.title + l2Node.title"
										:default-value="
											l2Node.title + l2Node.children!.at(0)!.title
										"
										:collapsible="true"
									>
										<AccordionItem
											v-if="l3Node.hasOwnProperty('children')"
											:value="l2Node.title + l3Node.title"
										>
											<AccordionTrigger
												:key="l2Node.title + l3Node.title"
												class="!py-3xl w-full text-sm font-semibold text-gray-800 capitalize !border-b-none"
												:value="l1Node.title"
											>
												{{ l3Node.title }}
											</AccordionTrigger>
											<AccordionContent class="!border-none">
												<DialogClose as-child>
													<CategoriesList
														:data="l3Node.children"
														:show-title="false"
														:l1-title="l1Node.title"
														variant="category"
														:view-all-handle="`/nodes/${l1Node.title.toLowerCase()}-${l3Node.title.toLowerCase().split(' ').join('-')}-${l3Node.id}`"
														:index="l3Index"
													/>
												</DialogClose>
											</AccordionContent>
										</AccordionItem>
									</Accordion>
								</div>

								<div
									v-if="!l2Node.children!.at(0)?.hasOwnProperty('children')"
									class="mt-3xl"
								>
									<DialogClose as-child>
										<CategoriesList
											:data="l2Node.children"
											:show-title="false"
											:l1-title="l1Node.title"
											variant="category"
											:view-all-handle="`/nodes/${l2Node.title.toLowerCase().split(' ').join('-')}-${l2Node.id}`"
											:index="l2Node.children?.length || -1"
										/>
									</DialogClose>
								</div>
							</DialogContent>
						</DialogPortal>
					</DialogRoot>
				</div>
			</AccordionContent>
		</AccordionItem>
	</Accordion>
</template>
