<script setup lang="ts">
import {
	Input,
	SingleSelectList,
	SingleSelectListItem,
	Skeleton,
} from '@laam/ui/base';
import { PhMagnifyingGlass, PhWarningCircle, PhX } from '@phosphor-icons/vue';
import { vElementVisibility } from '@vueuse/components';
import { useFetchAllBrands } from '~/data/entities';

const { data: brands, isLoading } = useFetchAllBrands();
const { $eventClient } = useNuxtApp();

const filteredBrandsList = computed(() => {
	if (!brands.value) return [];
	if (searchQuery.value) {
		return brands.value.filter((brand) =>
			brand.name
				.replace(/[^a-zA-Z0-9]/g, '')
				.toLowerCase()
				.includes(searchQuery.value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()),
		);
	} else return brands.value;
});

const brandGroup = computed(() => {
	const list = filteredBrandsList.value.map((brand) =>
		String(brand.name).trim().charAt(0).toUpperCase(),
	);
	let unique = [...new Set(list)];
	unique = unique.filter((item) => item !== '');
	return unique;
});

function groupedBrandList(initial: string) {
	const list = filteredBrandsList.value.filter((brand) =>
		brand.name.toLowerCase().startsWith(initial.toLowerCase()),
	);
	return list;
}

const selectedBrandGroup = ref('A');

function handleChange(value: string) {
	selectedBrandGroup.value = value;
	document.getElementById(value)?.scrollIntoView({
		block: 'start',
		inline: 'center',
	});
}

function checkSticky(state: boolean, brandInitial: string) {
	if (state === true) {
		selectedBrandGroup.value = brandInitial;
	}
}

const setSelectedTab = inject('setSelectedTab') as (tab: string) => void;

const searchQuery = ref('');
const emits = defineEmits(['updateOpen']);
const handleClick = (brand: { brandName: string; brandHandle: string }) => {
	emits('updateOpen');
	setSelectedTab('home');
	$eventClient.sendEvent('brand-click', {
		brandName: brand.brandName,
		brandHandle: brand.brandHandle,
		from: window.location.href,
	});
};
</script>

<template>
	<div v-if="!isLoading && brands" class="brands pb-[110px]">
		<div
			class="brand-list-header lg:top-7xl lg:left-3xl lg:right-3xl lg:border-b-0 fixed top-0 left-0 right-0 z-10 transition-all bg-white border-b"
		>
			<div
				class="py-md pl-3xl lg:pl-0 lg:pr-0 pr-md gap-3xl flex text-gray-800"
			>
				<Input
					v-model="searchQuery"
					size="sm"
					class="grow"
					placeholder="Search for brands"
				>
					<template #leftIcon>
						<PhMagnifyingGlass size="20" />
					</template>
					<template v-if="searchQuery" #rightIcon>
						<PhX size="20" @click="searchQuery = ''" />
					</template>
				</Input>

				<CartButton v-if="isMobileView()" @click="setSelectedTab('home')" />
			</div>

			<SingleSelectList
				class="brand-list-initials gap-md lg:py-md lg:px-0 px-3xl py-md w-fit lg:!w-full flex flex-row max-w-full overflow-auto"
				default-value="recommended"
				:model-value="selectedBrandGroup"
				@update:model-value="handleChange"
			>
				<SingleSelectListItem
					v-for="brandInitial in brandGroup"
					:key="brandInitial"
					:value="brandInitial"
					class="rounded-md h-8xl w-8xl !p-sm aspect-square text-gray-800 bg-white data-[state=checked]:text-white data-[state=checked]:bg-gray-800 text-sm font-semibold"
				>
					{{ brandInitial }}
				</SingleSelectListItem>
			</SingleSelectList>
		</div>

		<div
			v-if="brandGroup.length"
			class="py-3xl px-3xl lg:mt-[128px] mt-[80px] pb-[136px] lg:pb-[200px] lg:py-none lg:h-[95vh] overflow-auto"
		>
			<div
				v-for="(brandInitial, index) in brandGroup"
				:id="brandInitial"
				:key="index"
				:v-el="brandInitial"
			>
				<div
					v-element-visibility="
						(state: boolean) => checkSticky(state, brandInitial)
					"
					class="top-[120px] lg:top-0 lg:py-xl w-fit lg:w-full sticky bg-white"
					:class="{
						'!top-[10px]': brandInitial === '1' || brandInitial === '2',
					}"
				>
					<div
						class="w-9xl h-9xl flex items-center justify-center bg-gray-100 rounded"
					>
						<span
							ref="brandInitialRefs"
							class="text-xl font-semibold text-gray-800"
						>
							{{ brandInitial }}
						</span>
					</div>
				</div>

				<ul class="brand-list">
					<li
						v-for="(brand, index2) in groupedBrandList(brandInitial)"
						:key="index2"
						class="flex w-full"
					>
						<NuxtLink
							:to="`/brands/${brand.handle.toLowerCase()}`"
							class="ml-5xl lg:ml-0 lg:border-l-0 lg:pl-0 pl-6xl px-3xl py-lg block w-full border-l-2 border-r-0 border-gray-100"
							@click="
								handleClick({
									brandName: brand.name,
									brandHandle: brand.handle,
								})
							"
						>
							<span
								class="hover:text-gray-800 text-base font-normal text-gray-800 transition duration-100 ease-in"
							>
								{{ brand.name }}
							</span>
						</NuxtLink>
					</li>
				</ul>
			</div>
		</div>
		<div v-else class="h-[60vh] p-xl">
			<div
				class="gap-3xl flex flex-col items-center justify-center h-full text-center"
			>
				<div class="p-2xl rounded-xl border border-gray-200 shadow-xs">
					<PhWarningCircle class="text-gray-800 size-[28px]" />
				</div>

				<div class="gap-md flex flex-col">
					<span class="text-lg font-semibold text-gray-800">
						Oops! We couldn't find what you were looking for
					</span>
					<span class="text-sm text-gray-600">
						Don’t give up, try and modify your search!
					</span>
				</div>
			</div>
		</div>
	</div>

	<div v-else class="brands--loader">
		<div
			class="flex h-[56px] w-full bg-white border-b py-md pl-3xl pr-md gap-3xl lg:mt-0 mt-[56px]"
		>
			<Skeleton class="w-full h-full col-span-3 bg-gray-300" />
			<Skeleton class="size-9xl bg-gray-400" />
		</div>
		<div class="gap-md px-3xl py-md flex border-b">
			<Skeleton class="size-8xl rounded-medium bg-gray-800" />
			<Skeleton
				v-for="i in 8"
				:key="i"
				class="size-8xl rounded-medium bg-gray-300"
			/>
		</div>
		<div class="p-3xl">
			<div v-for="i in 5" :key="i">
				<Skeleton class="w-9xl h-9xl bg-gray-100 rounded" />
				<Skeleton
					class="ml-5xl pl-6xl px-3xl mx-3xl my-3xl py-lg block w-[90%] lg:w-fit border-l-2 border-r-0 border-gray-100"
				/>
			</div>
		</div>
	</div>
</template>

<style>
.brand-list-initials::-webkit-scrollbar {
	@apply hidden;
}

.component--brand-list-subheader::-webkit-scrollbar {
	@apply lg:hidden;
}
</style>
